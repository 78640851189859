<script>
import Partner from '@/components/Partner'
import ViewContainer from '@/components/ViewContainer'
import MainTitle from '@/components/MainTitle'

export default {
  name: 'Partnerhips',

  components: {
    ViewContainer,
    MainTitle,
    Partner
  },

  metaInfo () {
    const desc = 'Retrouvez la liste de mes partenaires et personne qui ont su être un grand soutien pour la chaîne Twitch ChatonDeAru'
    const pageName = 'Partenaires'

    return {
      title: pageName,
      meta: [
        { vmid: 'description', name: 'description', content: desc },
        { vmid: 'og:title', property: 'og:title', content: `${pageName} | Chaton De Aru` },
        { vmid: 'og:description', property: 'og:description', content: desc },
        // { vmid: 'og:image', property: 'og:image', content: this.planningPath },
        { vmid: 'twitter:title', name: 'twitter:title', content: `${pageName} | Chaton De Aru` },
        { vmid: 'twitter:description', name: 'twitter:description', content: desc }
        // { vmid: 'twitter:image', name: 'twitter:image', content: this.planningPath }
      ],
      link: [
        {
          rel: 'canonical',
          hreflang: 'fr',
          href: 'https://chatondearu.fr/partnerhips'
        }
      ]
    }
  }
}
</script>

<template>
  <ViewContainer class="partnerhips">
    <MainTitle>
      <template slot="subtitle">
        パートナー
      </template>
      Mes<br>Partenaires
    </MainTitle>

    <section class="box-row">
      <Partner>
        <template slot="title">
          LudoGest - Le réseau social Ludique
        </template>
        <template slot="description">
          Trouve facilement les endroits et évènements où jouer avec tes amis.
          Suis les actualités de tes bars à jeux préférés.
        </template>
        <template slot="link">
          <UiHotLink
            blank
            href="https://ludogest.fr"
          >
            Page internet
          </UiHotLink>
        </template>
      </Partner>

      <Partner>
        <template slot="title">
          Bar à Jeu Online
        </template>
        <template slot="description">
          Le BAJO est un lieu de rencontre en ligne pour des personnes intéressées par le monde ludique.
        </template>
        <template slot="link">
          <UiHotLink
            blank
            href="https://barajeuxonline.fr"
          >
            Page internet
          </UiHotLink>
        </template>
      </Partner>

      <Partner image="/img/partners/LDLC_logo.gif">
        <template slot="title">
          LDLC - Plan de Campagne
        </template>
        <template slot="description">
          Bienvenue à la boutique LDLC Plan de Campagne. Entrez dans l’univers informatique et high-tech de LDLC et venez découvrir les références incontournables du moment !
        </template>
        <template slot="link">
          <UiHotLink
            blank
            href="https://www.ldlc.com/magasins-ldlc/magasin-470046/13-ldlc-plan-de-campagne/"
          >
            Page internet
          </UiHotLink>
        </template>
      </Partner>

      <!-- <Partner>
        <template slot="title">
          Nannane
        </template>
        <template slot="description">
          Photographe proffessionel au Canada.
        </template>
        <template slot="link">
          <UiHotLink
            blank
            href=""
          >
            Page internet
          </UiHotLink>
        </template>
      </Partner>

      <Partner>
        <template slot="title">
          Eldenia
        </template>
        <template slot="description">
          Boutique Informatque, Qualité de service et passion.
        </template>
        <template slot="link">
          <UiHotLink
            blank
            href=""
          >
            Page internet
          </UiHotLink>
        </template>
      </Partner> -->
    </section>
  </ViewContainer>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.partnerhips {
  background-color: $pink;

  h1 {
    @include text-shadow(3);
  }
}
</style>
