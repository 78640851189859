<script>
import ViewContainer from '@/components/ViewContainer'
import MainTitle from '@/components/MainTitle'

export default {
  name: 'Tombola',

  components: {
    ViewContainer,
    MainTitle
  }
}
</script>

<template>
  <ViewContainer class="tombola">
    <MainTitle>
      <template slot="subtitle">
        パートナー
      </template>
      Tombola<br>Évènements Mars 2020
    </MainTitle>

    <p class="text-size-4 text-center">
      À Gagner le jeu <strong>Call of Duty®️ : WWII</strong><br>
      <small>et d'autre jeu en clé Steam uniquement.</small>
    </p>

    <p class="mrg-top-md">
      Pour participer il suffira de follow la chaîne Twitch.
      Le jeu débute le <strong>1er Mars</strong> et terminera le <strong>31 Mars</strong>.
    </p>

    <h2 class="mrg-top-md">
      Comment avoir un ticket ?
    </h2>
    <ul>
      <li>
        <span v-emoji="'🎟'" /> Un follow = 1 ticket de Tombola.
      </li>
      <li>
        <span v-emoji="'🎟'" /> Chaque dons de plus d'1€ ou Cheers vous donnerons un ticket supplémentaire
      </li>
      <li>
        <span v-emoji="'🎟'" /> Les VIP auront x4 tickets supplémentaires en plus pour la Tombola
      </li>
      <li>
        <span v-emoji="'🎟'" /> Les Subs auront x9 tickets supplémentaire en plus pour la Tombola <span v-emoji="'🎉'" />
      </li>
    </ul>

    <h2 class="mrg-top-md">
      Distribution des tickets
    </h2>
    <p>
      La distribution s'effectuera le <strong>28 Mars</strong> lors d'un live sur la <UiHotLink
        href="https://twitch.tv/chatondearu"
        blank
      >
        chaîne Twitch
      </UiHotLink>, les tickets seront distribués de manière aléatoire en fonction du nombre obtenu par chacun.<br>
      <i>ex: j'ai 1 ticket follow et 3 ticket de dons, lors de la distribution je me retrouve avec 4 tickets numéroté 56, 82, 98, 99.</i><br>
      la présence des participants est requise lors de la distribution des tickets, il est tout à fait envisageable de prevenir de son absence lors de la distribution à l'avance soit en demandant à un autre de receptionner les tickets soit en prévenant un Modérateur.
    </p>

    <h2 class="mrg-top-md">
      Tirage au sort
    </h2>
    <p>
      Le grand Tirage au sort se fera donc le <strong>Mardi 31 Mars</strong>, le tirage au sort se fera par un ordinateur avec un code open source réalisé par Chaton, le code sera disponnible sur la platforme Github.
    </p>
  </ViewContainer>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.tombola {
  background-color: $pink;
}
</style>
