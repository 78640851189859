<script>
import ViewContainer from '@/components/ViewContainer'
import MainTitle from '@/components/MainTitle'
import ChatMineur from '@/components/ChatMineur'

export default {
  name: 'Demineur',

  components: {
    ViewContainer,
    MainTitle,
    ChatMineur
  },

  metaInfo () {
    const desc = 'Un petit jeu Démineur développé en live sur la chaîne twitch de chatondearu en javascript et Vue JS'
    const pageName = 'Chat Mineur'

    return {
      title: pageName,
      meta: [
        { vmid: 'description', name: 'description', content: desc },
        { vmid: 'og:title', property: 'og:title', content: `${pageName} | Chaton De Aru` },
        { vmid: 'og:description', property: 'og:description', content: desc },
        // { vmid: 'og:image', property: 'og:image', content: this.planningPath },
        { vmid: 'twitter:title', name: 'twitter:title', content: `${pageName} | Chaton De Aru` },
        { vmid: 'twitter:description', name: 'twitter:description', content: desc }
        // { vmid: 'twitter:image', name: 'twitter:image', content: this.planningPath }
      ],
      link: [
        {
          rel: 'canonical',
          hreflang: 'fr',
          href: 'https://chatondearu.fr/demineur'
        }
      ]
    }
  }
}
</script>

<template>
  <ViewContainer class="demineur">
    <MainTitle>
      Chat|Mineur
    </MainTitle>

    <ChatMineur />
  </ViewContainer>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.demineur {
  background-color: $pink;
  padding: 16px;
}
</style>
