<script>
import { storage } from '@/firebase'
import ViewContainer from '@/components/ViewContainer'
import MainTitle from '@/components/MainTitle'

export default {
  name: 'Planning',

  components: {
    ViewContainer,
    MainTitle
  },

  data () {
    return {
      planningPath: '/img/loading.gif',
      loading: true,
      twitch: null
    }
  },

  created () {
    this.getPlanningLink()
  },

  mounted () {
    this.loadVideo()

    if (!this.$route.meta.prerenderDiffered) {
      window.prerenderReady = true
    }
  },

  methods: {
    async getPlanningLink () {
      const planningRef = storage.ref().child('plannings/planning.png')
      this.planningPath = await planningRef.getDownloadURL()
      this.loading = false

      this.$nextTick(() => {
        setTimeout(() => {
          window.prerenderReady = true
        }, 2000)
      })
    },

    loadVideo () {
      const Twitch = window.Twitch

      this.twitch = new Twitch.Player('background-video', {
        autoplay: true,
        muted: true,
        video: '969361729'
      })

      this.twitch.addEventListener(Twitch.Player.PLAY, () => {
        // this.twitch.getQualities()
        this.twitch.setQuality('160p30')
      })
    }
  },

  metaInfo () {
    const desc = 'Le planning de la chaîne ChatonDeAru sur Twitch! n\'hésites pas à follow pour être notifié'
    const pageName = 'Planning'

    return {
      title: pageName,
      meta: [
        { vmid: 'description', name: 'description', content: desc },
        { vmid: 'og:title', property: 'og:title', content: `${pageName} | Chaton De Aru` },
        { vmid: 'og:description', property: 'og:description', content: desc },
        { vmid: 'og:image', property: 'og:image', content: this.planningPath },
        { vmid: 'twitter:title', name: 'twitter:title', content: `${pageName} | Chaton De Aru` },
        { vmid: 'twitter:description', name: 'twitter:description', content: desc },
        { vmid: 'twitter:image', name: 'twitter:image', content: this.planningPath }
      ],
      link: [
        {
          rel: 'canonical',
          hreflang: 'fr',
          href: 'https://chatondearu.fr/planning'
        }
      ]
    }
  }
}
</script>

<template>
  <div class="planning">
    <div id="background-video" />
    <ViewContainer>
      <section>
        <MainTitle class="line-up">
          <template slot="subtitle">
            スケジュール
          </template>
          Planning<br> de la semaine
        </MainTitle>

        <UiHotLink
          href="https://twitch.tv/chatondearu"
          blank
          nostyle
        >
          <img
            class="planning-img"
            :src="planningPath"
            alt="planning de la semaine"
          >
        </UiHotLink>

        <p v-if="loading">
          Planning en cours de chargement <span v-emoji="'⚡️'" />
        </p>
      </section>
    </ViewContainer>
  </div>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.planning {
  background-attachment: fixed;
  background-image: url('~@/assets/background.jpg');
  background-size: cover;
  background-position: center center;

  section {
    position: relative;
    color: $white;

    h1 {
      @include text-shadow(3);
    }

    img.planning-img {
      max-width: 100%;
      @include card(2);
      border-radius: $gutter;
    }
  }
}

#background-video {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  iframe {
    position: absolute;
    top: -10%;
    bottom: 0;
    left: -10%;
    min-width: 120%;
    min-height: 120%;

    filter: blur(15px);
  }
}
</style>
