<template>
  <div>
    <UiForm>
      <UiFormInput
        v-model="size"
        number
        label="Size"
      />
      <UiFormInput
        v-model="nbrLandMines"
        number
        label="Nombre de Mines"
      />
      <UiButton
        type="secondary"
        @click="reset()"
      >
        Reset
      </UiButton>
    </UiForm>

    <div class="navbar mrg-top-lg">
      <p><span v-emoji="'😻'" />{{ nbrLandMines }}</p>
    </div>

    <div
      v-for="(row, y) in land"
      :key="y"
      class="row"
    >
      <div
        v-for="(dirt, x) in row"
        :key="x"
        class="dirt"
        :class="{ revealed: dirt.isRevealed }"
        :style="setBorderStyle(dirt)"
        @click="dig(dirt)"
        @click.right="addFlag(dirt, $event)"
      >
        <span
          v-if="!dirt.isRevealed && dirt.hasFlag"
          v-emoji="'🚩'"
        />
        <span
          v-if="dirt.isRevealed && dirt.hasLandMine"
          v-emoji="'😻'"
        />
        <span
          v-else-if="dirt.isRevealed && dirt.isNearLandMines"
          :class="[ `niveau-${dirt.isNearLandMines}` ]"
        >{{ dirt.isNearLandMines }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const CASE_MODEL = {
  isRevealed: false,
  hasLandMine: false,
  hasFlag: false,
  isNearLandMines: 0,
  x: 0,
  y: 0
}

export default {
  name: 'ChatMineur',

  data () {
    return {
      land: null,
      size: 15,
      nbrLandMines: 40,
      isFirstDig: true
    }
  },

  created () {
    this.init()
  },

  methods: {
    init () {
      const { size } = this
      const land = []

      for (let i = 0; i < size; i++) {
        const row = []

        for (let a = 0; a < size; a++) {
          row[a] = {
            ...CASE_MODEL,
            x: a,
            y: i
          }
        }

        land[i] = row
      }

      this.land = land
    },

    reset () {
      this.land = null
      this.isFirstDig = true

      this.init()
    },

    dig (dirt) {
      const { size } = this

      if (dirt.isRevealed || dirt.hasFlag) {
        return
      }

      if (this.isFirstDig) {
        this.buryLandMines(dirt)
        this.isFirstDig = false
      }

      dirt.isRevealed = true

      if (!dirt.hasLandMine && !dirt.isNearLandMines) {
        this.checkAround(dirt, (dirt) => {
          this.dig(dirt)
        })
      }

      if (dirt.hasLandMine) {
        // gameover
        for (let i = 0; i < size; i++) {
          for (let a = 0; a < size; a++) {
            this.land[i][a].isRevealed = true
          }
        }
      }
    },

    addFlag (dirt, event) {
      event.preventDefault()

      dirt.hasFlag = !dirt.hasFlag
    },

    checkAround (dirt, callback) {
      const { land } = this
      const { x, y } = dirt

      for (let i = (y - 1); i <= (y + 1); i++) {
        for (let a = (x - 1); a <= (x + 1); a++) {
          if (
            // dig don't check dirt passed itself
            !(i === y && a === x) &&
            i >= 0 && i < land.length &&
            a >= 0 && a < land[i].length
          ) {
            const dirtAround = this.land[i][a]
            callback(dirtAround)
          }
        }
      }
    },

    buryLandMines (dirt) {
      const { x, y } = dirt
      const { size, land, nbrLandMines } = this
      const tickets = []

      for (let i = 0; i < size; i++) {
        for (let a = 0; a < size; a++) {
          if (
            (a < (x - 1) || a > (x + 1)) ||
            (i < (y - 1) || i > (y + 1))
          ) {
            tickets.push({
              x: a,
              y: i
            })
          }
        }
      }

      let landmine = null
      for (let i = 0; i < nbrLandMines; i++) {
        const idTicket = Math.round(Math.random() * (tickets.length - 1))
        const { x, y } = tickets[idTicket]

        // set landmine
        landmine = land[y][x]
        landmine.hasLandMine = true

        this.checkAround(landmine, dirt => {
          dirt.isNearLandMines = dirt.isNearLandMines + 1
        })

        tickets.splice(idTicket, 1)
      }
    },

    setBorderStyle (dirt) {
      const { land } = this

      if (!dirt.isRevealed) {
        return {}
      }

      const { x, y } = dirt
      const style = {
        borderLeft: 'solid 3px brown',
        borderTop: 'solid 3px brown',
        borderRight: 'solid 3px brown',
        borderBottom: 'solid 3px brown',
        marginTop: '-3px',
        marginRight: '-3px',
        marginLeft: '-3px',
        marginBottom: '-3px'
      }

      if (y >= 1) {
        const top = land[y - 1][x]

        if (top.isRevealed) {
          style.borderTop = 0
          style.marginTop = 0
        }
      }

      if (y <= land.length - 2) {
        const bottom = land[y + 1][x]

        if (bottom.isRevealed) {
          style.borderBottom = 0
          style.marginBottom = 0
        }
      }

      if (x >= 1) {
        const left = land[y][x - 1]

        if (left.isRevealed) {
          style.borderLeft = 0
          style.marginLeft = 0
        }
      }

      if (x <= land[y].length - 2) {
        const right = land[y][x + 1]

        if (right.isRevealed) {
          style.borderRight = 0
          style.marginRight = 0
        }
      }

      return style
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/style/variables';

$dirt-size: 50px;

$revealed-color: #ae813e; //orange-brown 1
$revealed-color-odd: #b58f57; //orange-brown 2

$dirt-color: #8bc34a;
$dirt-color-odd: #4caf50;

$niveau-1: #3f51b5;
$niveau-2: #81c784;
$niveau-3: #f44336;
$niveau-4: #9c27b0;
$niveau-5: #ffc107;
$niveau-6: #4dd0e1;
$niveau-7: #607d8b;
$niveau-8: #000000;

.row {
  display: flex;
  flex:1;
  height: $dirt-size;

  .dirt {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: visible;
    align-items: center;
    width: $dirt-size;
    background-color: $dirt-color;

    span {
      display: block;
      width: 66%;
      height: 66%;
      font-weight: 600;
      color: white;
      font-size: 1.6rem;
      text-align: center;
      line-height: 2rem;

      .emoji {
        width: 100%;
        height: 100%;
      }

      &.niveau-1 {
        color: $niveau-1;
      }

      &.niveau-2 {
        color: $niveau-2;
      }

      &.niveau-3 {
        color: $niveau-3;
      }

      &.niveau-4 {
        color: $niveau-4;
      }

      &.niveau-5 {
        color: $niveau-5;
      }

      &.niveau-6 {
        color: $niveau-6;
      }

      &.niveau-7 {
        color: $niveau-7;
      }

      &.niveau-8 {
        color: $niveau-8;
      }
    }

    &:nth-child(2n) {
      background-color: $dirt-color-odd;
    }

    &.revealed {
      z-index: 1;
      background-color: $revealed-color;

      &:nth-child(2n) {
        background-color: $revealed-color-odd;
      }
    }

    &:hover {
      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: rgba(250, 250, 250, .23);
      }
    }
  }

  &:nth-child(2n) {
    .dirt {
      background-color: $dirt-color-odd;

      &:nth-child(2n) {
        background-color: $dirt-color;
      }

      &.revealed {
        background-color: $revealed-color-odd;
        &:nth-child(2n) {
          background-color: $revealed-color;
        }
      }
    }
  }
}
</style>
