import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/vuex'

import NotFound from '../views/NotFound'
import Home from '../views/Home'
import Planning from '../views/Planning'
import About from '../views/About'
import Events from '../views/Events'
import Tombola from '../views/Events/Tombola'
import Partnerhips from '../views/Partnerhips'
import Demineur from '../views/Demineur'

const Platform = () => import(/* webpackChunkName: "platform" */ '../views/Platform')
const Clans = () => import(/* webpackChunkName: "platform" */ '../views/Platform/Clans')
const ClansTest = () => import(/* webpackChunkName: "platform" */ '../views/Platform/Clans/ClansTest')
const PlatformHome = () => import(/* webpackChunkName: "platform" */ '../views/Platform/PlatformHome')
const PlatformConnect = () => import(/* webpackChunkName: "platform" */ '../views/Platform/PlatformConnect')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/planning',
    name: 'planning',
    component: Planning,
    meta: {
      prerenderDiffered: true
    }
  },
  {
    path: '/events',
    name: 'events',
    component: Events
  },
  {
    path: '/events/tombola-mars-2020',
    name: 'tombola',
    component: Tombola
  },
  {
    path: '/partnerhips',
    name: 'partnerhips',
    component: Partnerhips
  },
  {
    path: '/demineur',
    name: 'demineur',
    component: Demineur
  },
  {
    path: '/hq/v1',
    component: Platform,
    meta: {
      needAuth: true
    },
    children: [
      {
        path: '',
        name: 'platform',
        component: PlatformHome
      },
      {
        path: 'connect',
        name: 'platform-connect',
        component: PlatformConnect
      },
      {
        path: 'clans',
        name: 'clans',
        component: Clans
      },
      {
        path: 'clans/test',
        name: 'clans-test',
        component: ClansTest
      }
    ]
  },
  {
    path: '*',
    name: 'notfound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Need to be identified
  // TODO: cannot connect for the moment need to move connect script
  const NOT_CONNECTED_ROUTE = {
    name: 'home',
    replace: true
  }

  if (
    to.matched.some(record => record.meta.needAuth) &&
    to.name !== 'platform-connect'
  ) {
    if (!store.state.user.uid) {
      return next(NOT_CONNECTED_ROUTE)
    }
  }

  // feature flagging
  if (from.query.feature_flags && !to.query.feature_flags) {
    const nextQuery = {
      ...to.query,
      feature_flags: from.query.feature_flags
    }

    return next({
      ...to,
      query: nextQuery
    })
  } else {
    return next()
  }
})

export default router
