import Vue from 'vue'
import vueMeta from 'vue-meta'

import firebase from '@/firebase'
import router from './router'
import store from '@/vuex'
import './registerServiceWorker'
import App from './App.vue'

import { VERSION } from '@/utils/constants'

import ui from '@/components/ui/install'
import snackBarPlugin from '@/components/ui/UiSnackBar/plugin'
import emojiDirective from '@/utils/directives/emojiDirective'

Vue.config.productionTip = false

// setup vue plugins
Vue.use(ui)
Vue.use(vueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-ssr',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})
Vue.use(snackBarPlugin)

// add directives
Vue.directive('emoji', emojiDirective)

// Prepare Auth Events with the store
// TODO: add user fonctional gesture
firebase.auth().onAuthStateChanged(async user => {
  if (user) {
    store.dispatch('updateUid', user.uid)
    store.dispatch('getProfile')
    console.log('🔥 user connected: ', user.uid)

    const token = await user.getIdTokenResult()
    store.dispatch('setIsAdmin', token.claims.admin)
  } else {
    store.dispatch('removeUid')
    store.dispatch('setIsAdmin', false)
    store.dispatch('removeProfile')
    console.log('🔥 user disconnected')
  }

  if (store.state.platform.loading) {
    store.dispatch('setLoaded')
  }
})

// init the App
new Vue({
  router,
  store,
  created () {
    console.info(VERSION, process.env.VUE_APP_ENV)
  },
  render: h => h(App)
}).$mount('#app')
