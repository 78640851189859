<script>
import ViewContainer from '@/components/ViewContainer'
import MainTitle from '@/components/MainTitle'
import Card from '@/components/Card'

export default {
  name: 'Events',

  components: {
    ViewContainer,
    MainTitle,
    Card
  }
}
</script>

<template>
  <ViewContainer class="events">
    <MainTitle>
      Derniers<br>évènements
      <template slot="subtitle">
        イベント
      </template>
    </MainTitle>

    <section>
      <Card :path="{ name: 'tombola' }">
        <template slot="title">
          Grande Tombola du Mois de Mars
        </template>
        <template slot="description">
          Du 2020-03-01 Au 2020-03-31<br><br>
          À Gagner le jeu <strong>Call of Duty®️ : WWII</strong><br>
          <small>et d'autre jeu en clé Steam uniquement.</small>
        </template>
        <template slot="link">
          Voir l'event
        </template>
      </Card>
    </section>
  </ViewContainer>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.events {
  background-color: $pink;
}
</style>
