<script>
import ViewContainer from '@/components/ViewContainer'
import MainTitle from '@/components/MainTitle'

export default {
  name: 'About',

  components: {
    ViewContainer,
    MainTitle
  },

  metaInfo () {
    const desc = 'je suis passionné par le streaming et le partage avec une communauté ouverte, je vous invite à me découvrir avec toutes sortes de jeux vidéo ludique (ou non) dans la bonne humeur et le sourire :3'
    const pageName = 'À Propos'

    return {
      title: pageName,
      meta: [
        { vmid: 'description', name: 'description', content: desc },
        { vmid: 'og:title', property: 'og:title', content: `${pageName} | Chaton De Aru` },
        { vmid: 'og:description', property: 'og:description', content: desc },
        // { vmid: 'og:image', property: 'og:image', content: this.planningPath },
        { vmid: 'twitter:title', name: 'twitter:title', content: `${pageName} | Chaton De Aru` },
        { vmid: 'twitter:description', name: 'twitter:description', content: desc }
        // { vmid: 'twitter:image', name: 'twitter:image', content: this.planningPath }
      ],
      link: [
        {
          rel: 'canonical',
          hreflang: 'fr',
          href: 'https://chatondearu.fr/about'
        }
      ]
    }
  }
}
</script>

<template>
  <ViewContainer class="about">
    <MainTitle>
      <template slot="subtitle">
        について
      </template>
      À<br>Propos
    </MainTitle>

    <section class="box-row box-row-center">
      <p class="about__description">
        Je stream depuis plus d'un an à temps plein maintenant et je progresse tranquilement sur Twitch. J'ai vraiment découvert le streaming quand j'était Lead Frontend pour une startup parisienne où l'on développé une plateforme de streaming tel que Twitch (mais en mieux). Malheureusement le projet ne s'est pas concrétisé et me voilà sur Twitch que j'adore !<br>
        Je suis passionné de tech et je touche à tout, que ce soit design, code, vidéo, entrepreneuriat ect... mais mon réel but c'est surtout de partager mon amour pour les jeux vidéals avec mon tchat !<br>
        <span v-emoji="'🍜'" /> Grand curieux <span v-emoji="'🍱🍙'" /> si je peux parler du japon de temps en temps c'est bonus pour moi !<br>
        <span v-emoji="'🐱‍'" /> Aujourd'hui je suis passionné par le streaming et le partage avec une communauté ouverte, je vous invite à me découvrir avec toutes sortes de jeux vidéo ludique (ou non) dans la bonne humeur et le sourire :3
      </p>

      <div class="about__citation about-bloc-title">
        <h4>Citation</h4>
        <p>
          ⌈Je suis un chat. Je n'ai pas encore de nom. Je n'ai aucune idée du lieu où je suis né<br>
          吾輩は猫である。名前はまだ無い。どこで生れたかとんと見当がつかぬ<br>
          Wagahai wa neko de aru. Namae wa mada nai. Doko de umareta ka tonto kentō ga tsukanu⌋<br>
          <br>
          - Natsume Sōseki ⌈夏目 漱石⌋
        </p>
      </div>
    </section>

    <section class="about__planning">
      <h3 class="text-center">
        Live tout les soirs du Mercredi au Dimanche à partir de 19h00<br>
        <small class="pddg-top">Diffusion en Direct sur Twitch et VOD sur Youtube.</small>
      </h3>

      <p class="text-center">
        <UiButton
          type="primary"
          to="/planning"
        >
          planning
        </UiButton>
      </p>
    </section>

    <section class="about__social">
      <ul class="inline-list">
        <li>
          <UiButton
            href="https://twitch.fr/chatondearu"
            type="secondary"
            blank
            light
          >
            <UiIcon
              name="twitch"
              color="#a970ff"
            />
            Twitch
          </UiButton>
        </li>
        <li>
          <UiButton
            href="https://instagram.com/chatondearu"
            blank
            light
          >
            <UiIcon
              name="instagram"
              color="#ffffff"
            />
            Instagram
          </UiButton>
        </li>
        <li>
          <UiButton
            href="https://twitter.com/chatondearu"
            blank
            light
          >
            <UiIcon
              name="twitter"
              color="#35a1f2"
            />
            Twitter
          </UiButton>
        </li>
        <li>
          <UiButton
            href="https://discord.gg/7wscdCkR7P"
            blank
            light
            icon="discord"
          >
            Discord
          </UiButton>
        </li>
        <li>
          <UiButton
            href="https://tipeeestream.com/chatondearu/tip"
            blank
            light
            icon="gift"
          >
            Tipeee
          </UiButton>
        </li>
      </ul>
    </section>
  </ViewContainer>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.about {
  background-color: $pink;
  background-image: url('/img/bgs/bg_japan_003.png');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 750px auto;

  &-bloc-title {
    position: relative;

    h4 {
      position: absolute;
      bottom: 97%;
      color: $white;

      margin: 0;
      padding: 0;
    }
  }

  &__description {
    font-size: $font-size-1;
    line-height: 1.5em;

    padding: 0 0 $gutter-md 0;
    margin: $gutter-md 0 $gutter-lg 0;

    max-width: 550px;

    border-bottom: 1px solid rgba(236,232,225,.4);
  }

  &__citation {
    background-color: $grey-100;
    color: $dark;

    padding: $gutter;
    margin: $gutter-md 0;

    @include card(1);

    max-width: 550px;
  }

  &__planning, &__social {
    padding: $gutter;
    margin: $gutter-md 0;
  }
}
</style>
