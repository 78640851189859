<script>
import { mapState } from 'vuex'
import '@/assets/style/main.scss'

import Navigation from '@/components/Navigation'
import Footer from '@/components/Footer'

export default {
  name: 'App',

  components: {
    Navigation,
    Footer
  },

  computed: {
    ...mapState({
      loading: s => s.platform.loading
    })
  },

  metaInfo () {
    return {
      title: 'Home',
      titleTemplate: '%s | Chaton De Aru',
      meta: [
        { vmid: 'description', name: 'description', content: 'Rejoins la famille des moustachus en regardant la chaîne ChatonDeAru sur Twitch !' },
        { vmid: 'og:title', property: 'og:title', content: 'Chaton De Aru' },
        { vmid: 'og:type', property: 'og:type', content: 'website' },
        { vmid: 'og:description', property: 'og:description', content: 'Rejoins la famille des moustachus en regardant la chaîne ChatonDeAru sur Twitch !' },
        { vmid: 'og:image', property: 'og:image', content: '/img/icons/android-chrome-96x96.png' },
        { vmid: 'og:site_name', property: 'og:site_name', content: 'Chaton De Aru' },
        { vmid: 'og:locale', property: 'og:locale', content: 'fr_FR' },
        { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
        { vmid: 'twitter:site', name: 'twitter:site', content: '@chatondearu' },
        { vmid: 'twitter:title', name: 'twitter:title', content: 'Chaton De Aru' },
        { vmid: 'twitter:description', name: 'twitter:description', content: 'Rejoins la famille des moustachus en regardant la chaîne ChatonDeAru sur Twitch !' },
        { vmid: 'twitter:image', name: 'twitter:image', content: '/img/icons/android-chrome-96x96.png' },
        { vmid: 'twitter:creator', name: 'twitter:creator', content: '@chatondearu' }
      ],
      link: [
        { rel: 'canonical', hreflang: 'fr', href: 'https://chatondearu.fr' }
      ]
    }
  }
}
</script>

<template>
  <div id="app">
    <Navigation />

    <main>
      <UiLoader v-if="loading" />
      <router-view
        v-else
        class="view"
      />
    </main>

    <Footer />
  </div>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

#app {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100%;

  background-color: $dark;

  main {
    position: relative;
    padding-top: $gutter-lg;

    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  .view {
    position: relative;
    flex: 1;
    overflow: auto;
  }
}
</style>
