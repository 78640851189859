<script>
import Header from '@/components/Header'
import MainTitle from '@/components/MainTitle'
import TwitchEmbed from '@/components/TwitchEmbed'
import Card from '@/components/Card'

export default {
  name: 'Home',

  components: {
    TwitchEmbed,
    MainTitle,
    Header,
    Card
  }
}
</script>

<template>
  <div class="home">
    <Header />

    <div class="home__content">
      <div class="box-container">
        <MainTitle>
          <template slot="subtitle">
            ホーム
          </template>
          Accueil
        </MainTitle>

        <section class="home__content-container">
          <Card :path="{ name: 'planning' }">
            <template slot="title">
              Planning
            </template>
            <template slot="description">
              Retouve le programme des 7 prochains jours
            </template>
            <template slot="link">
              voir le planning
            </template>
          </Card>

          <Card :path="{ name: 'events' }">
            <template slot="title">
              Évènements
            </template>
            <template slot="description">
              Retrouve la liste des derniers gros évènements de la chaînes
            </template>
            <template slot="link">
              voir la liste
            </template>
          </Card>

          <Card :path="{ name: 'demineur' }">
            <template slot="title">
              Jeu
            </template>
            <template slot="description">
              Tu t'ennuies ? fais une partie de ChatMineur (Démineur)
            </template>
            <template slot="link">
              Jouer
            </template>
          </Card>

          <Card href="https://shop.chatondearu.fr">
            <template slot="title">
              La boutique Officiel de Chaton !
            </template>
            <template slot="description">
              Tu veux posséder Kitten Cap ou avoir le swag, va vite découvrir la première collection de ChatonDeAru
            </template>
            <template slot="link">
              Voir la Boutique
            </template>
          </Card>
        </section>
      </div>
    </div>

    <TwitchEmbed />
  </div>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.home {
  background-color: $palette-pink;

  &__content {
    position: relative;
    padding: $gutter-lg 0;

    &:before {
      content: '';
      position: absolute;
      top: -48px;
      left: 0;
      width: 30%;
      border-bottom: 48px solid $palette-pink;
      border-right: 48px solid transparent;
    }

    &-container {
      display: flex;
      flex-wrap: wrap;

      // border-left: .5px solid $white;
      padding: $gutter;
    }
  }
}
</style>
