<script>
export default {
  name: 'Card',

  props: {
    path: {
      type: Object,
      default: () => ({})
    },

    href: {
      type: String,
      default: null
    },

    light: Boolean
  }
}
</script>

<template>
  <div>
    <component
      :is="href ? 'UiHotLink' : 'router-link'"
      :href="href"
      :to="href ? null : path"
      blank
      nostyle
    >
      <!-- <router-link :to="path"> -->
      <div
        :class="{light}"
        class="card"
      >
        <p class="card__title">
          <slot name="title" />
        </p>
        <p class="card__description">
          <slot name="description" />
        </p>
        <p class="text-right">
          <slot name="link" /> <UiIcon
            color="white"
            name="arrow-right"
          />
        </p>
      </div>
    <!-- </router-link> -->
    </component>
  </div>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.card {
  color: $white;
  position: relative;
  border: 1px solid $white;
  margin: $gutter-md;
  padding: $gutter;
  text-align: left;

  min-width: 250px;
  max-width: 500px;

  flex: 1;

  &__title {
    font-weight: 600;
    font-size: $font-size-3;
    line-height: 1em;

    margin-bottom: $gutter;
  }

  &__description {
    margin-bottom: $gutter-sm;
  }

  &:before {
    content: '';
    position: absolute;
    top: -4px;
    left: -1px;
    width: 42px;
    border-bottom: 4px solid $white;
    border-right: 8px solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    right: -13px;
    bottom: -13px;
    width: 24px;
    height: 24px;
    border-bottom: 1px solid $white;
    background-color: $palette-pink;
    transform: rotate(135deg);
  }

  &:hover {
    cursor: pointer;
    background-color: transparentize($dark, .85);
    border-color: $dark;

    &:before, &:after {
      border-color: $dark;
    }
  }

  &.light {
    color: $white;
    border-color: $white;

    &:before, &:after {
      border-color: $white;
      background-color: $dark;
    }

    &:before {
      border-right: 8px solid transparent;
    }

    &:hover {
      background-color: transparentize($palette-pink, .85);
      border-color: $palette-pink;

      &:before, &:after {
        border-color: $palette-pink;
      }
    }
  }
}
</style>
