<template>
  <div id="twitch-embed" />
</template>

<script>
export default {
  name: 'TwitchEmbed',

  mounted () {
    const embed = new window.Twitch.Embed('twitch-embed', {
      width: '100%',
      height: '100%',
      channel: 'chatondearu',
      parent: ['chatondearu.fr'],
      layout: 'video'
    })

    embed.addEventListener(window.Twitch.Embed.VIDEO_READY, () => {
      const player = embed.getPlayer()
      player.play()
    })
  }
}
</script>

<style lang="scss">
@import '~@/assets/style/variables';

#twitch-embed {
  margin-top: 16px;
  background: $dark;

  @media screen and (min-width: $screen-md + 1) {
    height: $screen-lg * 9/16;
  }

  @media screen and (max-width: $screen-md) {
    height: $screen-md * 9/16;
  }

  @media screen and (max-width: $screen-sm) {
    height: $screen-sm * 9/16;
  }

  @media screen and (max-width: $screen-xs) {
    height: $screen-xs * 9/16;
  }
}
</style>
