<template>
  <header class="header">
    <div class="header__logo">
      <Logo />
    </div>
  </header>
</template>

<script>
import Logo from '@/components/Logo'

export default {
  name: 'Header',

  components: {
    Logo
  }
}
</script>

<style lang="scss">
@import '~@/assets/style/variables';

.header {
  $header-height-default: 500px;

  font-size: 17px;
  color: $white;
  background-image: url('~@/assets/background.png');
  background-size: cover;
  background-position: center center;
  min-height: $header-height-default;

  display: flex;
  justify-content: center;

  nav {
    position: relative;
    display: flex;

    margin: $gutter-sm;
  }

  @media screen and (max-width: $screen-md) {
    min-height: $header-height-default / 1.5;
  }

  @media screen and (max-width: $screen-sm) {
    min-height: $header-height-default / 2.5;
  }

  &__logo {
    flex: 1;
    max-width: 500px;
  }
}
</style>
