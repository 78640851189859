<script>
export default {
  name: 'Partner',

  props: {
    image: {
      type: String,
      default: null
    },

    light: Boolean
  }
}
</script>

<template>
  <div
    :class="{ light }"
    class="partner"
  >
    <picture class="partner__head">
      <img :src="image">
    </picture>
    <p class="partner__title">
      <slot name="title" />
    </p>
    <p class="partner__description">
      <slot name="description" />
    </p>
    <p class="text-right">
      <slot name="link" /> <UiIcon
        color="white"
        name="arrow-right"
      />
    </p>
  </div>
</template>

<style lang="scss">
@import '~@/assets/style/variables';

.partner {
  color: $white;
  position: relative;
  border: 1px solid $white;
  margin: $gutter;
  padding: $gutter-sm;
  background-color: transparentize($dark, .85);

  min-width: 250px;
  max-width: 500px;

  flex: 1;

  &__head {
    img {
      max-width: 100%;
    }

    margin-bottom: $gutter-sm;
  }

  &__title {
    font-weight: 600;
    font-size: 1.2em;

    margin-bottom: $gutter-sm;
  }

  &__description {
    margin-bottom: $gutter-sm;
  }

  &:hover {
    border-color: $dark;
  }

  &.light {
    color: $white;
    border-color: $white;
    background-color: transparentize($palette-pink, .85);

    &:hover {
      border-color: $palette-pink;
    }
  }
}
</style>
