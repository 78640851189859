<template>
  <div class="footer">
    <section>
      <nav>
        <ul>
          <li class="title">
            Réseaux Sociaux
          </li>
          <li>
            <UiButton
              href="https://twitch.fr/chatondearu"
              blank
              light
            >
              <UiIcon
                name="twitch"
                color="#a970ff"
              />
              Twitch
            </UiButton>
          </li>
          <li>
            <UiButton
              href="https://instagram.com/chatondearu"
              blank
              light
            >
              <UiIcon
                name="instagram"
                color="#ffffff"
              />
              Instagram
            </UiButton>
          </li>
          <li>
            <UiButton
              href="https://twitter.com/chatondearu"
              blank
              light
            >
              <UiIcon
                name="twitter"
                color="#35a1f2"
              />
              Twitter
            </UiButton>
          </li>
        </ul>
      </nav>
      <nav>
        <ul>
          <li class="title">
            Navigation
          </li>
          <li>
            <UiButton
              :to="{ name: 'home' }"
              exact
              light
            >
              Accueil
            </UiButton>
          </li>
          <li>
            <UiButton
              :to="{ name: 'about' }"
              exact
              light
            >
              À propos
            </UiButton>
          </li>
          <li>
            <UiButton
              :to="{ name: 'planning' }"
              exact
              light
            >
              Planning
            </UiButton>
          </li>
          <li>
            <UiButton
              :to="{ name: 'events' }"
              exact
              light
            >
              Évènements
            </UiButton>
          </li>
          <li>
            <UiButton
              :to="{ name: 'partnerhips' }"
              exact
              light
            >
              Partenaires
            </UiButton>
          </li>
        </ul>
      </nav>
      <nav>
        <ul>
          <li class="title">
            Mes autres pages
          </li>
          <li>
            <UiButton
              href="https://shop.chatondearu.fr"
              blank
              light
            >
              La Boutique
            </UiButton>
          </li>
          <li>
            <UiButton
              href="https://medium.com/@ChatonDeAru"
              blank
              light
            >
              Le Blog Medium
            </UiButton>
          </li>
        </ul>
      </nav>
    </section>
    <p>Site réalisé avec VueJs et Firebase. Copyright © 2021 ChatonDeAru</p>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss">
@import '~@/assets/style/variables';

.footer {
  position: relative;
  background-color: $dark;
  color: $white;
  padding-bottom: $gutter-lg;
  text-align: center;

  section {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }

  nav {
    .title {
      text-align: center;
      text-decoration: underline;
      padding-bottom: $gutter;
    }

    margin-top: $gutter-lg;
    margin-right: $gutter;
    padding-bottom: $gutter-lg;
  }
}
</style>
